#navbaritems {
    font-size: xx-large;
    font-weight: bold;
    font-style: italic;
    padding: 20px;
}

#navbartitle {
    color: rgb(0, 0, 0);
}

#navbartext {
    color: rgb(0, 0, 0);
}

#navbaritems :hover{
    background-color:rgb(119, 180, 250);
}

@media (max-width: 700px) {
    #navbaritems {
        font-size: medium;
    }
}

