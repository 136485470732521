#introdiv {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    text-align: center;
    background-image: url("./components/assets/background.jpg");
    background-size: cover;
  }
  
#hometext {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

#profilepicture {
  height: 60%;
  width: 60%;
  border-radius: 50%;
}


#separatorrow {
  padding: 100px;
}




#biophoto {
  height: 50%;
  width: 50%;
  border: rgb(104, 101, 101) solid 1px;
  box-shadow: 7px 7px #414141;
  margin-bottom: 20px;
}

#biotitle {
  font-size: xx-large;
  font-weight: bold;
}

#bioparagraph {
  font-size: x-large;
}

@media (max-width: 800px) {
  #hometext {
    font-size: x-large;
  }

  #separatorcontainer {
    padding: 40px;
  }

  #separatorrow {
    padding: 0px;
  }

  #separatortext {
    font-size: small;
  }

  #biotitle {
    font-size: x-large;
  }

  #bioparagraph {
    font-size: small;
  }
}






@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');